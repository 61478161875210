/*------------------------------------*\
    #TYPE
\*------------------------------------*/

/**
 * Typographical base selectors.
 */

/**
 * Headings 1–6.
 */
@include headings() {
    @include font-weight(regular);
    color: $color-headings;
}





/**
 * A generic heading style which can be applied to any headings.
 */
.heading {
    @include font-size(22px);
}

.heading--highlight {
    @include font-size(20px);
    color: $color-white;
    text-transform: uppercase;
    @include font-weight(bold);
}

.heading-sub {
    @include font-size(16px);
    @include font-weight(bold);
    margin-bottom: 0;
    color: $color-heading-sub;
}

.section-heading {
    @include font-size(32px);
    position: relative;
    text-transform: uppercase;
    @include media-query(palm) {
        @include font-size(24px);
        line-height: 1.5;
    }
}

.section-heading--dark {
    color: $color-white;
    & > .section-heading__text {
        &:after {
            background-color: $color-white;
        }
    }
}

.section-heading--brand {
    color: $color-brand;
    & > .section-heading__text {
        &:after {
            background-color: $color-brand;
        }
    }
}

.section-heading--top {
    margin-bottom: 0;
    & > .section-heading__text {
        &:after {
            top: auto;
            bottom: 100%;
            margin-top: 0;
            margin-bottom: halve($inuit-base-spacing-unit);
        }
    }
}

.section-heading--no-line {
    margin-bottom: 0;
    & > .section-heading__text {
        &:after {
            content: none;
        }
    }
}

.section-heading--right {
    text-align: right;
    & > .section-heading__text {
        &:after {
            left: auto;
            right: 0;
        }
    }
}

    .section-heading__text {
        display: inline-block;
        position: relative;

        &:after {
            content: '';
            width: 50%;
            height: 1px;
            background-color: $color-gray-ui;
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: halve($inuit-base-spacing-unit);
        }
    }

.section-heading--margin-large{
    margin-bottom: 3em;
}

.section-heading--centre {
    text-align: center;

    & > .section-heading__text {
        &:after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

    




/**
 * Emboldened elements.
 */
a,
strong, b,
dt {
    //@include font-weight(bold);
}





/**
 * Code-like elements.
 */
code, kbd, samp {
    color: $color-code;
    font-family: Inconsolata, Monaco, Consolas, "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
    font-style: normal;
}

pre {
    white-space: pre;
    word-wrap: normal;
    overflow: auto;
    padding: $spacing-unit--small;
    background-color: $color-pre-background;
    border-radius: $base-round;

    &,
    code, kbd, samp {
        color: $color-pre;
    }

}





/**
 * Figures.
 *
 * 1. Outdent figures by an arbitrary amount at the relevant viewport size.
 * 2. Reindent figcaptions.
 */
figure {

    @media screen and (min-width: 1380px) {
        margin-left: -128px; /* [1] */
    }

    > img {
        border-radius: $base-round;
    }

}

    figcaption {
        @include font-size(12px);

        @media screen and (min-width: 1380px) {
            margin-left: 128px; /* [2] */
        }

    }





/**
 * Horizontal rules.
 */
hr {
    border: none;
    border-bottom: 1px solid $color-gray-ui;
    margin-bottom: $spacing-unit - 1px;
}





/**
 * Highlighted text.
 */
mark {

    pre & {
        background: none;
        border-bottom: 1px solid;
        color: inherit;
    }

}





/**
 * Text-alignment.
 */
.text-banner {
    text-align: center;
}


.text-large {
    @include font-size(32px);
    line-height: 2;
    @include media-query(palm){
        @include font-size(20px);
        line-height: 1.5;
    }
}

.bold {
    font-weight: bold !important;
}

.sub-heading {
    color: $color-brand;
    text-transform: uppercase;
    @include font-size(16px);
    font-weight: bold;
}
