$inuit-enable-block--list:     false !default;

@if ($inuit-enable-block--list == true) {

    /**
     * Left-aligned blocks.
     */

    .#{$inuit-block-namespace}block--list,
    %#{$inuit-block-namespace}block--list {
        display: table-row;

        & >.#{$inuit-block-namespace}block__img,
        & > %#{$inuit-block-namespace}block__img,
        & > .#{$inuit-block-namespace}block__body,
        & > %#{$inuit-block-namespace}block__body {
            display: table-cell;
        }
    }

}
