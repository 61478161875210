/*------------------------------------*\
    #FEATURE-LIST
\*------------------------------------*/

/**
 * The feature list component houses large, showcase-style blocks of content. It
 * is currently used on the Case Studies page.
 */

.gallery-list {
    @extend %clearfix;
    padding: 0;
    list-style: none;
    background-color: inherit;
    margin-left: 0;
    margin-bottom: 4em;

    @include media-query(desk) {
        overflow: hidden;
        margin-top: 9em;
        margin-left: -3%;
        margin-right: -3%;
        transform: rotate(5deg);
    }
    
    
}

    .gallery-list__item {
    
        position: relative;
        background: inherit;
        height: 280px;
        
        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            height: 100px;
            width: 4000px;
            background-color: inherit;
            z-index: 1;
            transform: rotate(5deg);
        }

        &:before {
            transform-origin: 0 100%;
            right: 0;
            top: -220px;
            left: 50%;
            margin-left: -2000px;
        }

        &:after {
            transform-origin: 0 100%;
            bottom: 130px;
            right: 0;
            left: 50%;
            margin-left: -2000px;
        }

        @include media-query(desk) {
            float: left;
            width: 33.333333333%; /* [1] */
            transform: rotate(-5deg);
            height: 360px;
        
            &:before, &:after {
                display: none;
            }

            &:nth-child(3n + 1):nth-last-child(2) {
                &, & + li {
                    width: 50%;
                }
            }

            &:nth-child(3n+1):last-child {
                width: 100%;
            }

        }

    }

        /**
         * 1. Faux fluid height in 16:9 ratio.
         * 2. Space items apart by two pixels…
         * 3. …except the first row and first column.
         */
        .gallery-list__link {
            position: absolute;
            display: block;
            cursor: pointer;
            
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background-color: $color-links;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            
            &:hover{
                .gallery-list__title {
                    transform: scale3d(1.05,1.05,1) translateY(-50%);
                }
            }

            @include media-query(desk) {
                top: -20%;
                bottom: -20%;
            }

        }

            .gallery-list__state {
                display: none;
                &:checked {
                    & + .gallery-list__reveal {
                        visibility: visible;
                        opacity: 1;
                    }

                    & ~ .gallery-list__title {
                        position: fixed;
                        z-index: 10;
                        transform: scale3d(1.05,1.05,1) translateY(-50%);
                    }
                }
            }

            .gallery-list__close {
                display: none;

                &:checked {
                    + .gallery-list__close-btn {
                        display: none;
                    }
                }

                &:not(:checked) {
                    ~ .gallery-list__container {
                        //height: 100vh;
                        //overflow: hidden;
                        .tint {
                            filter: none;
                            transform: none;
                        }

                        .triangle {
                            z-index: inherit;
                        }

                        .gallery-list, .gallery-list__item {
                            transform: none;
                        }
                    }

                    + .gallery-list__close-btn {
                        display: block;
                    }
                }
            }

            .gallery-list__container {
                height: auto;
            }

            .gallery-list__close-btn {
                position: fixed;
                right: 12px;
                top: 12px;
                background-color: rgba(black, .5);
                color: white;
                border: 2px solid white;
                z-index: 9;
                padding: 5px 10px;
                border-radius: 5px;
                font-weight: bold;
            }

            .gallery-list__reveal {
                opacity: 0;
                visibility: hidden;
                width: 100%;
                height: 100%;
                display: block;
                position: fixed;
                z-index: 8;
                left: 0;
                top: 0;
                background-color: black;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }

            /**
             * Sit link text over the top of the list item.
             *
             * 1. Center the text-box vertically in the list item.
             * 2. Optically sit the text itself centrally in the list item.
             */
            .gallery-list__title {
                @include font-size(16px, 1.5);
                @include font-weight(bold);
                text-align: center;
                position: absolute;
                top: 50%; /* [1] */
                right: 0;
                left:  0;
                padding: $spacing-unit--small $spacing-unit--large;
                transform: translateY(-50%);
                color: #fff;
                text-transform: uppercase;
                transition: all .5s ease-in-out;
                margin: 0;
                text-shadow: 0px 0px 12px rgba(black, .8);

                @include media-query(desk) {
                    @include font-size(20px, 1.5);
                }
            }

                .gallery-list__sub {
                    display: block;
                    @include font-size(14px);
                    margin-top: $spacing-unit--tiny;
                }
