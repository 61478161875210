/*------------------------------------*\
    #QUOTES
\*------------------------------------*/

/**
 * Inline quotes.
 *
 */
q {
    font-style: italic;
    quotes: "‘" "’" "“" "”";

    &:before {
        content: open-quote;
    }

    &:after {
        content: close-quote;
    }

}





/**
 * Block quotes.
 *
 * 1. Hang opening punctuation.
 */
blockquote {
    margin-right: 2 * $spacing-unit;
    margin-left:  2 * $spacing-unit;
    font-style: italic;
    quotes: "“" "”";

    p {
        text-indent: -0.41em; /* [1] */

        &:before {
            content: open-quote;
        }

        &:after {
            content: no-close-quote;
        }

        &:last-of-type {
            margin-bottom: 0;

            &:after {
                content: close-quote;
            }

        }

    }

    q {
        quotes: "‘" "’";

        &:before {
            content: open-quote;
        }

        &:after {
            content: close-quote;
        }

    }

}
