/*------------------------------------*\
    #SHOW-HIDE
\*------------------------------------*/

@mixin hide() {
    display: none !important;
    opacity: 0 !important;
    position: absolute !important;
    height: 1px; width: 1px; 
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

@mixin show() {
    display: block !important;
}

.hide { @include hide(); }
.show { @include show(); }

// Loop over our breakpoints defined in _settings.responsive.scss
@each $breakpoint in $breakpoints {

  // Get the name of the breakpoint.
  $alias: nth($breakpoint, 1);

  @include media-query($alias) {

    .hide-#{$alias}{
        @include hide();
    }

  }

  .show-#{$alias}{
    display: none;
  }

  @include media-query($alias) {

    .show-#{$alias}{
        @include show();
    }

  }

}