.triangle {
    position: relative;
    z-index: 5;
    min-height: 280px;
    margin-bottom: 100px;

    @include media-query(lap-and-up) {
        margin-bottom: 262px;

    }

    
    &.triangle--start {
        margin-bottom: 0;
        &:after {
            display: none;
        }
    }

    &.triangle--end {
        &:before {
            display: none;
        }
    }
}

.triangle:before, .triangle:after {
    content: '';
    display: block;
    position: absolute;
    width: 3000px;
    height: 100px;
    
    left: 50%;
    margin-left: -1500px;
    background-color: inherit;
    z-index: -1;
    transform: rotate(5deg);

    @include media-query(lap-and-up) {
        height: 300px;
    }
}

.triangle:before {
    transform-origin: 100% 0;
    top: 80px;
    @include media-query(lap-and-up) {
        top: 0;
    }
}

.triangle:after {
    transform-origin: 0% 100%;
    bottom: 80px;
    @include media-query(lap-and-up) {
        bottom: 0;
    }
}

.triangle__content--adjust {
    margin-top: -40px;
    @include media-query(lap-and-up) {
        margin-top: -100px;
    }
    @include media-query(desk) {
        margin-top: -140px;
    }
}