/*------------------------------------*\
    #WIDTHS
\*------------------------------------*/

/**
 * A series of width helper classes that you can use to size things like grid
 * systems. Classes can take a fraction-like format (e.g. `.u-2/3`) or a spoken-
 * word format (e.g. `.u-2-of-3`). Use these in your markup:
 *
 * <div class="u-7/12">
 */

// By default we will create wholes, halves, thirds, quarters, and fifths.
// Predefine this Map to override.
$inuit-widths-columns: (
    1,
    2,
    3,
    4,
    5,
) !default;

@include inuit-widths($inuit-widths-columns);
