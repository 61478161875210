/*------------------------------------*\
    #BANDS
\*------------------------------------*/

.band {
    padding-top:    $spacing-unit;
    padding-bottom: $spacing-unit;
    margin-top: -2px;

    @include media-query(desk) {
        padding-top:    $spacing-unit--large;
        padding-bottom: $spacing-unit--large;
    }

}

.band--large {
    padding-top: $spacing-unit--large;
    padding-bottom: $spacing-unit--large;
}

.band--highlight {
    background-color: $color-band-highlight-background;
}

.band--tint {
    background-color: $color-band-tint-background;
}

.band--dark {
    background-color: $color-band-dark-background;
    color: $color-band-dark;
}
