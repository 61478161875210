.tint {
    @include media-query(desk) {
        filter: grayscale(100%);
        -webkit-transition: -webkit-filter .2s ease-in-out;
        transition: -webkit-filter .2s ease-in-out;
        transform: translateZ(0);
    }

    &:before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);

        transition: background-color .2s ease-in-out;
    }

    &:hover{
        filter: none;

        &:before {

            background-color: rgba(0,0,0,.3);
     
        }
    }
}

.section-img {
    background-size: cover;
    background-position: top center;
    background-color: white;
    height: 1028px;
    width: 100%;

    @include media-query(palm) {
        height: 720px;
    }
}

.footer {
    @include media-query(desk) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.footer__copy {
}

.footer__credit {
    position: relative;
    margin-top: 1em;
    @include media-query(desk) {
        margin-top: 0;
    }
}

.footer__credit-copy {
    display: block;
    font-weight: normal;
    font-size: .8em;
    margin-bottom: .6em;
    @include media-query(desk) {
        bottom: 100%;
        position: absolute;
    }
}

.footer__img {
    height: 40px;
}