/*------------------------------------*\
    #FEATURE-LIST
\*------------------------------------*/

.feature-list{
    list-style: none;
    padding: 0;
    margin: 0;
}

    .feature-list__item{
        display: inline-block;
        vertical-align: top;
        position: relative;
        min-height: 120px;
        margin-bottom: 1em;
        padding-left: 100px;
        
        @include media-query(lap-and-up) {
            width: 50%;
            padding: 0 .5em;
            text-align: center;
        }
        
        @include media-query(desk) {
            width: 33.333333%;
            padding: 0 .5em;
            text-align: center;
        }
        
        &:hover{
            .feature-list__img{
                //transform: scaleX(1.05) scaleY(1.05);
            }
        }
    }

    .feature-list__img{
        position: absolute;
        width: 80px;
        height: 80px;
        left: 0;
        top: 0;
        transition: transform .2s ease-in-out;
        @include media-query(lap-and-up) {
            width: 160px;
            height: 160px;
            margin-bottom: 2em;
            position: static;
        }
        @include media-query(desk) {
            
        }
    }
