/*------------------------------------*\
    #HERO
\*------------------------------------*/

.hero{
    position: relative;
}

    .hero__body {

    }

    .hero__img-wrapper {
        text-align: right;
    }

    .hero__img {
        margin: quadruple($spacing-unit) auto double($spacing-unit);
        padding: 0 $spacing-unit;
        display: block;
        max-width: 300px;

        @include media-query(lap-and-up) {
            margin: 0 0 $spacing-unit 0;
            display: inline-block;
        }
    }

    .hero__heading {
        @include vw(48px, 1.2, 720px);
        margin-bottom: double($spacing-unit);
    }

    .hero__copy {
        @include font-size(18px, 1.5);
        margin-bottom: double($spacing-unit);
        @include media-query(desk) {
            @include font-size(22px, 1.5);
        }
    }

    .hero__cta {

    }

    .hero__logo {
        max-width: 180px;
        width: 40vw;
        display: inline-block;
        position: relative;
        margin-bottom: halve($spacing-unit);
        @include media-query(lap-and-up) {
            width: 180px;
            margin-bottom: $spacing-unit;
        }
    }

    .hero__emblems {
        text-align: center;
        @include media-query(lap-and-up) {
            text-align: right;
        }
    }

    .hero__emblem {
        height: 52px;
        margin: 0 $spacing-unit double($spacing-unit);
        @include media-query(lap-and-up) {
            margin-left: $spacing-unit;
            height: 52px;
        }
    }