/*------------------------------------*\
    #CAROUSEL
\*------------------------------------*/
.carousel {
    overflow: hidden;
    width: 100%;
    text-align: center;
}

    .carousel__panes {
        margin:  0;
        padding: 0;
        list-style: none;
        position: relative;
        display: table;
        width: 500%;
        overflow: hidden;
        @include vendor(filter, blur(0));

        -webkit-animation: carousel 45s alternate infinite;
           -moz-animation: carousel 45s alternate infinite;
                animation: carousel 45s alternate infinite;
    }

    .carousel__panes:hover {
        -webkit-animation-play-state: paused;
           -moz-animation-play-state: paused;
                animation-play-state: paused;
    }
        
        .carousel__pane {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            width: 20%; /* 100 / number of panes */
        }

            .carousel__link {
                color: inherit;
                border-bottom: 1px solid $color-links-carousel;
                @include font-weight(regular);
                @include vendor(transition, 0.2s border-color);

                @include attention() {
                    text-decoration: none;
                    border-bottom-color: darken($color-links-carousel, 10%);
                }

            }





/**
 * Scroll the carousel (all hard-coded; yuk!) and apply a subtle blur to imply
 * motion/speed. Equation for the carousel’s transitioning and delayed points in
 * order to complete an entire animation (i.e. 100%):
 *
 * nx + (n-1)y = 100
 *
 * where n is the number of slides, x is the percentage of the animation spent
 * static, and y is the percentage of the animation spent animating.
 *
 * This carousel has five panes, so:
 *
 * 5x + (5-1)y = 100
 *
 * To work out y if we know n and decide on a value for x:
 *
 * (100 - (n * x)) / n-1 = y
 *
 * If we choose that x equals 17.5 (i.e. a frame spends 17.5% of the animation’s
 * total time *not* animating), and we know that n equals 5, then y = 3.125:
 *
 * (100 - (5 * 17.5)) / 4 = 3.125
 *
 * Static for 17.5%, transition for 3.125%, and so on, until we hit 100%.
 *
 * If we were to choose that x equals 15, then we would find that y equals 6.25:
 *
 * (100 - (5 * 15)) / 4 = 6.25
 *
 * If y comes out as zero-or-below, it means the number we chose for x was too
 * large: pick again.
 *
 * N.B. We also include a halfway point in the middle of our transitioning
 *      frames to which we apply a subtle blur. This number is derived from:
 *
 *      (a * x) + (a-1 * y) + (y / 2)
 *
 *      where a is the frame in question (out of n frames).
 *
 *      The halfway point between frames 3 and 4 is:
 *
 *      (3 * 17.5) + (2 * 3.125) + (3.125 / 2) = 60.3125
 *
 * I’m pretty sure this is all a mess. To any kind person reading this who would
 * be able to improve it, I would be very grateful if you would advise :)
 */
@-webkit-keyframes carousel{
    0%,
    17.5%       { -webkit-transform: translate3d(   0 , 0, 0); -webkit-filter: blur(0  ); }
    20.625%,
    38.125%     { -webkit-transform: translate3d( -20%, 0, 0); -webkit-filter: blur(0  ); }
    41.25%,
    58.75%      { -webkit-transform: translate3d( -40%, 0, 0); -webkit-filter: blur(0  ); }
    61.875%,
    79.375%     { -webkit-transform: translate3d( -60%, 0, 0); -webkit-filter: blur(0  ); }
    82.5%,
    100%        { -webkit-transform: translate3d( -80%, 0, 0); -webkit-filter: blur(0  ); }
    19.0625%,
    39.6875%,
    60.3125%,
    80.9375%    {                                              -webkit-filter: blur(2px); }
}

@-moz-keyframes carousel{
    0%,
    17.5%       {    -moz-transform: translate3d(   0 , 0, 0);    -moz-filter: blur(0  ); }
    20.625%,
    38.125%     {    -moz-transform: translate3d( -20%, 0, 0);    -moz-filter: blur(0  ); }
    41.25%,
    58.75%      {    -moz-transform: translate3d( -40%, 0, 0);    -moz-filter: blur(0  ); }
    61.875%,
    79.375%     {    -moz-transform: translate3d( -60%, 0, 0);    -moz-filter: blur(0  ); }
    82.5%,
    100%        {    -moz-transform: translate3d( -80%, 0, 0);    -moz-filter: blur(0  ); }
    19.0625%,
    39.6875%,
    60.3125%,
    80.9375%    {                                                 -moz-filter: blur(2px); }
}

@keyframes carousel{
    0%,
    17.5%       {         transform: translate3d(   0 , 0, 0);         filter: blur(0  ); }
    20.625%,
    38.125%     {         transform: translate3d( -20%, 0, 0);         filter: blur(0  ); }
    41.25%,
    58.75%      {         transform: translate3d( -40%, 0, 0);         filter: blur(0  ); }
    61.875%,
    79.375%     {         transform: translate3d( -60%, 0, 0);         filter: blur(0  ); }
    82.5%,
    100%        {         transform: translate3d( -80%, 0, 0);         filter: blur(0  ); }
    19.0625%,
    39.6875%,
    60.3125%,
    80.9375%    {                                                      filter: blur(2px); }
}
