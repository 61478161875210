/*------------------------------------*\
    #PAGE-FOOT
\*------------------------------------*/

.page-foot {
    @include font-size(12px);
    padding-bottom: 0;

    @include media-query(desk) {
        padding-bottom: $spacing-unit;
    }

}
