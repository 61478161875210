///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// Spit out vendor prefixes in one go. Very pragmatic, far from perfect.
@mixin vendor($property, $value...) {
    -webkit-#{$property}: $value;
       -moz-#{$property}: $value;
            #{$property}: $value;
}

// Apply ‘attention’ styles in one go.
@mixin attention($self: false) {

    @if($self != false) {

        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }

    }

    @else {

        &:hover,
        &:active,
        &:focus {
            @content;
        }

    }

}

// Generate Helvetica Neue’s numbered font-weights from their aliases.
@mixin font-weight($font-weight) {

    @if ($font-weight == "regular") {
        font-weight: 300;
    }

    @if ($font-weight == "medium") {
        font-weight: 500;
    }

    @if ($font-weight == "bold") {
        font-weight: 700;
    }    

}

// Create all specified heading styles in one go.
@mixin headings($from: 1, $to: 6) {

    %base-heading {
        @content;
    }

    @if $from >= 1 and $to <= 6{

        @for $i from $from through $to{

            h#{$i} {
                @extend %base-heading;
            }

        }

    }

}

%center-children,
.center-children {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@function get-vw($target, $context-width) {
    // 1vw is equal to 1% of the viewport width
    $vw-context: ($context-width * .01);
    @return ($target / $vw-context) * 1vw;
}

@mixin vw($size, $line-height, $context-width: $wrapper) {
    @include font-size($size, $line-height);
    font-size: get-vw($size, $context-width);
    @media (min-width: $wrapper) {
        @include font-size($size, $line-height);
    }
}