/*------------------------------------*\
    #PAGE
\*------------------------------------*/

html {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    
    @include media-query(palm) {
        font-size: 14px;
    }
    min-height: 100vh;
    overflow-y: scroll;
}

html, body {
    
    //min-height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    

}

body {
    height: 100%;
    overflow-y: auto;
}