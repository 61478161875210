.svg-wrapper {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 100%;
}

.svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}