/*------------------------------------*\
    #WRAPPERS
\*------------------------------------*/

.wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding-right: $spacing-unit--small;
    padding-left:  $spacing-unit--small;

    @include media-query(lap-and-up) {
        padding-right: $spacing-unit;
        padding-left:  $spacing-unit;
    }

}

/**
 * This is a bit of a magic number; a wrapper this wide will allow us to ‘hang’
 * one icon out of the rest of the page’s ‘grid’. It is used primarily on the
 * home page promo boxes.
 */
.wrapper--wide {
    max-width: 1276px;
}
