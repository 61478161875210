/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a, .link-brand {
    text-decoration: none;
    color: $color-links;

    @include attention() {
        text-decoration: underline;
    }

}

//.link-quiet {
//    color: $color-gray-light;
//    text-decoration: underline;
//
//    @include attention() {
//        color: $color-links;
//    }
//
//}

.link-secret {
    color: inherit;
    @include font-weight(regular);

    @include attention() {
        color: $color-links;
        text-decoration: none;
    }

}