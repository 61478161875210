/*------------------------------------*\
    #ICONIC
\*------------------------------------*/

.iconic {
    width:  64px;
    height: 64px;

    path {
        fill: $color-iconic;
    }

}
