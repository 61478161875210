/*------------------------------------*\
    #PAGE-HEAD
\*------------------------------------*/

$header-border-width:   6px;
$header-max-height:     480px;

/**
 * The site’s main page-head can have two different states:
 *
 * 1) Regular page-head with no backgrounds or extra treatments; it just
 *    contains the logo and nav.
 * 2) A masthead that has a fluid-height (becoming fixed after a certain point)
 *    which has a large background image, and some supporting text.
 *
 * The regular page-head is incredibly simple, but the masthead version has some
 * slightly intermingled dependency with the wrapper that lives inside it.
 */


/**
 * 1. A slightly smaller padding on smaller viewports.
 * 2. Grow to full-sized padding on large enough viewports.
 */
.page-head {
    padding-top:    $spacing-unit--small; /* [1] */
    padding-bottom: $spacing-unit--small; /* [1] */
    background-color: transparent;
    min-height: 300px;

    @include media-query(lap-and-up) {
        padding-top:    $spacing-unit; /* [2] */
        padding-bottom: $spacing-unit; /* [2] */
    }


    &.triangle:before {
        background-color: $color-page-head-background;
    }

}





/**
 * Large site headers act more like mastheads. They have a faux-fluid-height
 * which is controlled by the wrapping element inside it.
 *
 * 1. Mastheads will typically have dark backgrounds, so we need to make sure
 *    the contrast is okay. This value is subject to change as the background
 *    image changes.
 * 2. We need to delegate a lot of the masthead’s layout to its wrapper element
 *    rather than the masthead itself: it is to this wrapper that most things
 *    are positioned.
 * 3. The wrapper needs positioning context for us to lay our nav and masthead
 *    text in.
 * 4. Faux-fluid-height technique: simply create the illusion of fluid height by
 *    creating space via a percentage padding, and then position everything over
 *    the top of that. This percentage gives us a 16:9 ratio.
 * 5. When the viewport is at 758px wide, our 16:9 ratio means that the masthead
 *    is currently rendered at 480px high. Let’s…
 * 6. …seamlessly snip off the fluid feature at this height, and…
 * 7. …fix the height at 480px. This means that we should see no jumps in height
 *    as the masthead moves from fluid to fixed. This actual value takes into
 *    account the padding and the top border on the header itself.
 */
.page-head--masthead {
    background: url(/assets/hero_bg.jpg) center center #2e2620;
    background-size: cover;
    background-position: bottom left;
    color: $color-masthead; /* [1] */
    position: relative;

    > .wrapper { /* [2] */
        position: relative; /* [3] */
        min-height: 320px;
        height: 40vh; /* [7] */
        padding-right: 6px;
        padding-left:  6px;
        @include media-query(lap-and-up) {
            min-height: 400px;
            height: 60vh; /* [7] */
        }
        
    }

    &:before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjUiLz4KICAgIDxzdG9wIG9mZnNldD0iMzAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
        background: linear-gradient(to bottom, rgba(0,0,0,.5) 0%, rgba(0,0,0,0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=0 );
    }

}

/**
 * Mastheads will have some large text in them. Let’s position that bottom-left.
 */
.page-head__text {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: auto;
    color: $color-masthead;
    @include font-size(24px);

    @include media-query(lap-and-up) {
        @include font-size(48px);
    }

    @include media-query(desk) {
        @include font-size(72px);
    }

}

@keyframes fadeEntranceExit {
  0% {opacity: 0; transform: translateX(-80px);}
  30% {opacity: 1; transform: translateX(0);}
  80% {opacity: 1; transform: translateX(0);}
  100% {opacity: 0; transform: translateX(80px);}
}

.page-head__copy {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: -12px;
    text-transform: uppercase;
    font-weight: 800;
    @include font-size(22px);
    color: #fff;
    opacity: 0;
    text-shadow: 1px 2px 4px rgba(black, .8);

    animation-name: fadeEntranceExit;
    animation-duration: 4s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;

    @include media-query(lap-and-up) {
        @include font-size(36px);
    }

}
