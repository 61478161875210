/*------------------------------------*\
    #PAGE-MICRO
\*------------------------------------*/

.page-micro {
    background-color: $color-page-micro-background;
    
    &,
    a {
        color: $color-page-micro;
    }

}

    .page-micro__copy {
        @include font-size(12px);
        display: block;
    }
